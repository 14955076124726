import React from 'react';
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Translation} from "@autopay.io/translation";
import {Icon} from "@autopay.io/style";

interface NetworkErrorProps {
    reset: () => void
}

const UnhandledError = (props: NetworkErrorProps) => {
    return (
        <div className={'warning_container'}>
            <Icon icon={'excl_red'} />
            <h1>{Translation.messages().tapnpark.device.error_unexpected}</h1>
            <Spacer />
            <Button onClick={props.reset}>{Translation.messages().tapnpark.device.retry}</Button>
        </div>
    );
}

export default UnhandledError;
