import React, {useEffect, useState} from 'react';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import Spinner from "@autopay.io/style/lib/components/Spinner/Spinner";
import {constants} from "../../constants";
import {isValidToken, register} from '../../services/apiService';
import {Translation} from "@autopay.io/translation";
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {RegisterDevice} from "../root/types";

interface RegistrationProps extends RouteComponentProps<{deviceId: string}>{
}

const Register = (props: RegistrationProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const {deviceId} = props.match.params;
        const existingDeviceId = localStorage.getItem(constants.DEVICE_ID);

        if (existingDeviceId && existingDeviceId === deviceId) {
            isValidToken().then(() => {
                console.log('Already on valid device, redirecting...');
                setLoading(false);
            }).catch(() => {
                console.log('Found invalid device data, retrying registration...');
                registerDevice(deviceId);
            });
            return;
        } else {
            localStorage.clear();
        }

        console.log('Registering device: ' + deviceId);
        registerDevice(deviceId);

    }, [])

    const registerDevice = (deviceId: string) => {
        const request: RegisterDevice = {device_id: deviceId}
        register(request).then((response) => {
            if (response.type === "DATA") {
                const data = response.data;
                localStorage.setItem(constants.DEVICE_ID, data.device_id);
                localStorage.setItem(constants.CLIENT_ID, data.client_id);
                localStorage.setItem(constants.CLIENT_SECRET, data.client_secret);
                localStorage.setItem(constants.TOKEN, data.token);
            } else if (response.type === "ERROR_MESSAGE") {
                setError(response.message);
            } else {
                setError("Error registering device")
            }

            setLoading(false);
        })
    }

    const handleTryAgain = () => setError(undefined);

    if (loading) {
        return <Spinner size="lg" delay="none"/>
    } else if (error) {
        return (
            <>
                <div className="icon icon_warning_animated" />
                <h1>{Translation.messages().tapnpark.device.register_error}</h1>
                <Spacer />
                <Button onClick={handleTryAgain}>{Translation.messages().common.buttons.try_again}</Button>
            </>
            )
    } else {
        return <Redirect to='/' />
    }
}

export default Register;
