export default {
    EXPIRATION_DATE: "expirationDate",
    CHECKIN_SENT_DATE: "checkin_sent_date",
    TOKEN: "token",
    CLIENT_ID: "clientId",
    CLIENT_SECRET: "clientSecret",
    DEVICE: "device",
    DEVICE_ID: "deviceId",
    TIMEOUT_TIME: 10000,
}
