import React, {useState} from 'react';
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import {Translation} from "@autopay.io/translation";
import {constants} from "../../../../../constants";
import {PlateWithStatus} from "../../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../../components/DisplayVehicleCharacteristics";

interface NoMatchProps {
    plateId?: string;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const NoMatch = (props: NoMatchProps) => {
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const confirm = () => {
        setConfirmed(true);
        setTimeout(() => {
           props.reset();
        }, constants.TIMEOUT_TIME);
    };

    if (confirmed) {
        return (
            <>
                <PlateWithStatus plateId={props.plateId || ""} statusIcon={"ERROR"} />
                {props.vehicleBrand &&
                    <DisplayVehicleCharacteristics vehicleBrand={props.vehicleBrand} vehicleModel={props.vehicleModel} vehicleColor={props.vehicleColor}/>
                }
                <div className='parking-validated'>
                    <h1>
                        <span className={'parking-validated-title'}>{Translation.messages().tapnpark.device.error_not_found}</span>
                    </h1>
                    <span className={'parking-validated-info'}>{Translation.messages().tapnpark.device.error_not_found_message}</span>
                </div>
                <Spacer size={"lg"}/>
                <Button color="primary" onClick={props.reset}>{Translation.messages().tapnpark.device.back}</Button>

            </>
        )
    } else {
        return (
            <>
                <h1>{Translation.messages().tapnpark.device.confirm_reg}</h1>
                <PlateWithStatus plateId={props.plateId || ""} />
                {props.vehicleBrand &&
                    <>
                        <DisplayVehicleCharacteristics vehicleBrand={props.vehicleBrand} vehicleModel={props.vehicleModel} vehicleColor={props.vehicleColor}/>
                        <Spacer size={'md'}/>
                    </>
                }
                <Spacer size={"xs"}/>
                <div>
                    <Button color="primary-green" onClick={props.reset} className="margin-right">{Translation.messages().tapnpark.device.confirm_reg_no}</Button>
                    <Button onClick={confirm}>{Translation.messages().tapnpark.device.confirm_reg_yes}</Button>
                </div>
            </>
        )
    }
}

export default NoMatch;
