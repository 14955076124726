import React from "react";

type Status = "SUCCESS" | "ERROR";
interface PlateWithStatusProps{
    plateId: string;
    statusIcon?: Status
}
export const PlateWithStatus = (props: PlateWithStatusProps) => {
    if (props.statusIcon === "SUCCESS") {
        return <div className='vehicle-reg-container'>
            <div className={'padding_icon'}>
                <div className={'valid-icon'}>&nbsp;</div>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    } else if (props.statusIcon === "ERROR") {
        return (
          <div className="vehicle-reg-container">
            <div className={"padding_icon"}>
              <div className={"warning-icon"}>&nbsp;</div>
              <span className={"vehicle-reg"}>{props.plateId}</span>
            </div>
          </div>
        );
    } else {
        return <div className='vehicle-reg-container plain-vehicle-reg-container'>
            <div className={'padding_icon plain-vehicle-reg-container'}>
                <span className={'vehicle-reg'}>{props.plateId}</span>
            </div>
        </div>;
    }
}

export default PlateWithStatus;
