import React, { Fragment, SFC } from 'react';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';

import Header from './components/header/AppHeader';

const AppContainer: SFC = ({ children }) => (
  <Fragment>
    <Header />
    <Container fluid={true} className='main'>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  </Fragment>
);

export default AppContainer;
