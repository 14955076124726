import {Translation} from "@autopay.io/translation";
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import React from "react";

interface ConcurrentLimitExceeded {
    reset: () => void;
}

const ConcurrentLimitExceeded = (props: ConcurrentLimitExceeded) => {

    const onResetClick = () => {
        props.reset();
    };

    return (
        <>
            <div className="icon icon_warning_animated" />
            <h1>{Translation.messages().tapnpark.device.error_concurrent_limit_exceeded}</h1>
            <Spacer />
            <Button color="primary" onClick={onResetClick}>{Translation.messages().tapnpark.device.reset}</Button>
        </>
    );
};

export default ConcurrentLimitExceeded;
