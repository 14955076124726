import {Translation} from "@autopay.io/translation";
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import Button from "@autopay.io/style/lib/components/Button/Button";
import React from "react";
import PlateWithStatus from "../../../components/PlateWithStatus";
import {DisplayVehicleCharacteristics} from "../../../components/DisplayVehicleCharacteristics";

interface AlreadyValidatedProps {
    plateId?: string;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const AlreadyValidated = (props: AlreadyValidatedProps) => {

    const onResetClick = () => {
        props.reset();
    };

    return (
        <>
            <PlateWithStatus plateId={props.plateId || ""} statusIcon={"ERROR"} />
            {props.vehicleBrand &&
                <>
                    <DisplayVehicleCharacteristics vehicleBrand={props.vehicleBrand} vehicleModel={props.vehicleModel} vehicleColor={props.vehicleColor}/>
                    <Spacer size={'md'}/>
                </>
            }
            <h1>{Translation.messages().tapnpark.device.error_already_validated}</h1>
            <Spacer size={"xs"}/>
            <Button color="primary" onClick={onResetClick}>{Translation.messages().tapnpark.device.reset}</Button>
        </>
    );
};

export default AlreadyValidated;
