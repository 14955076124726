import React, {Component} from 'react';
import InvalidDevice from "./components/InvalidDevice";
import NoMatch from "./components/NoMatch";
import NetworkError from "./components/NetworkError";
import {ErrorTypes} from '../../types';
import DeviceDeleted from "./components/DeviceDeleted";
import UnhandledError from "./components/UnhandledError";
import AlreadyValidated from "./components/AlreadyValidated";
import ConcurrentLimitExceeded from './components/ConcurrentLimitExceeded';

interface ErrorProps {
    type?: ErrorTypes;
    plateId?: string;
    reset: () => void;
    vehicleBrand?: string;
    vehicleModel?: string;
    vehicleColor?: string;
}

const Error = (props: ErrorProps) => {
    const {type} = props;
    
    if (type === 'invalidDevice') {
        return <InvalidDevice />
    } else if (type === 'networkError') {
        return <NetworkError reset={props.reset} />
    } else if (type === 'noMatch') {
        return <NoMatch plateId={props.plateId || ''}
                        reset={props.reset}
                        vehicleBrand={props.vehicleBrand}
                        vehicleModel={props.vehicleModel}
                        vehicleColor={props.vehicleColor} />
    } else if (type === 'deviceDeleted') {
        return <DeviceDeleted resetDevice={props.reset}/>
    } else if (type === 'unhandled') {
        return <UnhandledError reset={props.reset}/>
    } else if (type === 'alreadyValidated') {
        return <AlreadyValidated plateId={props.plateId || ''}
                                 reset={props.reset}
                                 vehicleBrand={props.vehicleBrand}
                                 vehicleModel={props.vehicleModel}
                                 vehicleColor={props.vehicleColor}/>
    } else if (type === 'concurrentLimitExceeded') {
        return <ConcurrentLimitExceeded reset={props.reset}/>
    } else {
        return <Component />
    }
};

export default Error;
