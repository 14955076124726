import {config, constants} from "../constants";
import JwtDecode from "jwt-decode";
import pRetry from "p-retry";
import {
    DeviceInformation,
    RefreshTokenResponse,
    RegisterDevice,
    RegistrationResponse,
    TokenDto,
    ValidateRequest,
    ValidationAndVehicleInformation
} from "../scenes/root/types";
import {
    HttpErrorResponseWithMessage,
    HttpResponse,
    HttpResponseData,
    HttpResponseDeviceDeleted,
    HttpResponseOk,
    HttpResponseUnauthorized
} from "./types";

export const register = (request: RegisterDevice): Promise<HttpResponse<RegistrationResponse>> => {
    return makeRequestWithRetry<RegistrationResponse>('POST', `/device/register`, request);
}

export const getInformation = (): Promise<HttpResponse<DeviceInformation>> => {
    return makeRequestWithRetry<DeviceInformation>('GET', `/device/information`);
}

export const validateAndVehicleInfo = (request: ValidateRequest): Promise<HttpResponse<ValidationAndVehicleInformation>> => {
    return makeRequestWithRetry<ValidationAndVehicleInformation>('POST', `/validation/v2`, request);
}

export const sendStatusCheckIn = (): Promise<HttpResponse<void>> => {
    return makeRequestWithRetry<void>('POST', `/device/statusCheckin`);
}

export const getRefreshToken = (): Promise<void> => {
    const clientId = localStorage.getItem(constants.CLIENT_ID);
    const clientSecret = localStorage.getItem(constants.CLIENT_SECRET);

    if (clientId && clientSecret) {
        const request = {
            client_id: clientId,
            client_secret: clientSecret
        };

        return makeRequest<RefreshTokenResponse>('POST', `/refreshToken`, request).then((response) => {
            if (response.type === "DATA") {
                const token = response.data.access_token;
                const tokenExpirationMillis = JwtDecode<TokenDto>(token).exp * 1000;
                const expirationDate = new Date(tokenExpirationMillis);

                localStorage.setItem(constants.TOKEN, token.toString());
                localStorage.setItem(constants.EXPIRATION_DATE, String(expirationDate));
                return Promise.resolve();
            } else if (response.type === "ERROR_MESSAGE") {
                throw new Error('Error fetching refresh token')
            }
        });

    } else {
        return Promise.reject('Missing credentials');
    }
}

export const isValidToken = (): Promise<void> => {
    const token = localStorage.getItem(constants.TOKEN);
    const expirationDate = localStorage.getItem(constants.EXPIRATION_DATE) || "";

    const expirationDateInMilli = new Date(expirationDate).getTime();
    const currentTimestampInMillis = Date.now();

    if (!token || !expirationDateInMilli || expirationDateInMilli < currentTimestampInMillis) {
        return getRefreshToken().then(() => Promise.resolve()).catch((err) => Promise.reject(err.message));
    } else {
        return Promise.resolve();
    }
}

const retryFetch = async (method: string, url: string, payload?: {}) => {
    const response = await makeRequest<any>(method, url, payload);

    if (response.type === "DELETED") {
        throw new pRetry.AbortError('The device has been deleted');
    }

    return response;
}

async function makeRequestWithRetry<T>(method: string, url: string, payload?: {}): Promise<HttpResponse<T>> {
    const retryRequest = async () => {
        return await pRetry(() => retryFetch(method, url, payload), {
            retries: 100
        });
    }

    const response = await retryRequest();

    if (response.type === 'UNAUTHORIZED') {
        return getRefreshToken().then(async (response) => {
            return await retryRequest();
        })
    } else {
        return response;
    }
}

async function makeRequest<T>(method: string, url: string, payload?: {}): Promise<HttpResponse<T>> {
    return fetch(`${config.TNP_PUBLIC_API_BASE_URL}${url}`, {
        method,
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem(constants.TOKEN)}}`,
        }
    }).then(async (response) => {
        const contentType = response.headers.get('content-type');

        if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 200) {
            const json = await response.json();
            return {
                type: 'DATA',
                data: json,
            } as HttpResponseData<T>;
        } else if (response.status === 204) {
            return { type: 'OK' } as HttpResponseOk
        } else if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 410) {
            return { type: 'DELETED'} as HttpResponseDeviceDeleted;
        } else if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 401) {
            return { type: 'UNAUTHORIZED'} as HttpResponseUnauthorized;
        } else if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 400) {
            const json = await response.json();
            return { type: 'ERROR_MESSAGE', message: json.message, error_id: json.error_id} as HttpErrorResponseWithMessage;
        } else {
            throw new Error('Error in request')
        }
    }).catch((err) => Promise.reject(err.message))
}
