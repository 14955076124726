import React from 'react';
import '../../assets/styles/KeyboardComponent.scss';

interface KeyboardComponentProps {
    handleOnClick: any,
    handleBackspace: any
}

interface KeyboardComponentState {
    keyboardLayout: string
}

class KeyboardComponent extends React.Component<KeyboardComponentProps, KeyboardComponentState> {

    public state = {
        keyboardLayout: "regular",
    };

    render() {
        const customLayout = [
        [
            { value: "\u00C5", code: "cust01"},
            { value: "\u00C4", code: "cust02"},
            { value: "\u0100", code: "cust03"},
            { value: "\u0104", code: "cust04"},
            { value: "\u00C6", code: "cust05"},
            { value: "\u0106", code: "cust06"},
            { value: "\u010C", code: "cust07"},
            { value: "\u0112", code: "cust08"},
        ],
        [
            { value: "\u0118", code: "cust11"},
            { value: "\u0122", code: "cust12"},
            { value: "\u012A", code: "cust13"},
            { value: "\u0136", code: "cust14"},
            { value: "\u013B", code: "cust15"},
            { value: "\u0141", code: "cust16"},
            { value: "\u0145", code: "cust17"},
            { value: "\u0143", code: "cust18"},
        ],
        [
            { value: "\u00D6", code: "cust21"},
            { value: "\u00D3", code: "cust22"},
            { value: "\u00D8", code: "cust23"},
            { value: "\u0160", code: "cust24"},
            { value: "\u015A", code: "cust25"},
            { value: "\u00DF", code: "cust26"},
            { value: "\u016A", code: "cust27"},
        ],
        [
            { value: "ABCD..", code: "custom"},
            { value: "\u00DC", code: "cust31"},
            { value: "\u017D", code: "cust32"},
            { value: "\u0179", code: "cust33"},
            { value: "\u017B", code: "cust34"},
            { value: "\u2B60", code: "backspace"}
        ]
    ];

        const regularLayout = [
        [
            { value: "1", code: "1"},
            { value: "2", code: "2"},
            { value: "3", code: "3"},
            { value: "4", code: "4"},
            { value: "5", code: "5"},
            { value: "6", code: "6"},
            { value: "7", code: "7"},
            { value: "8", code: "8"},
            { value: "9", code: "9"},
            { value: "0", code: "0"}
        ],
        [
            { value: "Q", code: "KeyQ"},
            { value: "W", code: "KeyW"},
            { value: "E", code: "KeyE"},
            { value: "R", code: "KeyR"},
            { value: "T", code: "KeyT"},
            { value: "Y", code: "KeyY"},
            { value: "U", code: "KeyU"},
            { value: "I", code: "KeyI"},
            { value: "O", code: "KeyO"},
            { value: "P", code: "KeyP"}
        ],
        [
            { value: "A", code: "KeyA"},
            { value: "S", code: "KeyS"},
            { value: "D", code: "KeyD"},
            { value: "F", code: "KeyF"},
            { value: "G", code: "KeyG"},
            { value: "H", code: "KeyH"},
            { value: "J", code: "KeyJ"},
            { value: "K", code: "KeyK"},
            { value: "L", code: "KeyL"}
        ],
        [
            { value: "\u00C4\u00D8\u00D3\u010C..", code: "custom"},
            { value: "Z", code: "KeyZ"},
            { value: "X", code: "KeyX"},
            { value: "C", code: "KeyC"},
            { value: "V", code: "KeyV"},
            { value: "B", code: "KeyB"},
            { value: "N", code: "KeyN"},
            { value: "M", code: "KeyM"},
            { value: "\u232B", code: "backspace"}
        ]
    ];

        const getClassName = (value: any) => {
            let result;
            if (!isNaN(parseInt(value)) || value === 'backspace' || value === 'custom') {
                result = "primary-btn"
            }
            if (value === 'backspace') {
                result += ' btn-backspace'
            }
            if (value === 'custom') {
                result += ' btn-widekey'
            }
            return result;
        };

        const {keyboardLayout} = this.state;
        const layoutState = keyboardLayout === 'regular' ? regularLayout : customLayout;

        return(
            <div className="keyboard-container">
                {layoutState.map((row, index) => (
                    <div className="button-row" key={index}>
                            {row.map((key, index) => (
                                <button key={index} className={getClassName(key.code)}
                                        onClick={() => this.onBtnClick(key.code, key.value)}>
                                    <div className="btn-content">
                                        {key.value}
                                    </div>
                                </button>
                            ))}
                    </div>
                ))}
            </div>
        );
    }

    onBtnClick = (pressedKey: string | string[], value: string | KeyboardEvent["code"][]) => {
        if (pressedKey === 'custom') {
            this.setState({keyboardLayout: this.state.keyboardLayout === 'regular' ? 'custom' : 'regular'});
            return
        }
        if (pressedKey === 'backspace') {
            this.props.handleBackspace();
            return
        }
        this.props.handleOnClick(value);
    }
}

export default KeyboardComponent;
