import React from 'react';
import {DeviceInformation} from "../types";

interface FooterProps {
    deviceInfo: DeviceInformation
}

export const Footer = (props: FooterProps) => {
    return (
        <div className="operator-footer">
            { props.deviceInfo.operator && props.deviceInfo.logo && (
                <>
                    <hr />
                    <img className="pull-right"  src={`data:${props.deviceInfo.logo.contentType};base64,${props.deviceInfo.logo.data}`} />
                </>
            )}
        </div>
    );
}

export default Footer;
