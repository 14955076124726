import React from "react";
import {Translation} from "@autopay.io/translation";

interface DisplayVehicleColorProps {
    vehicleColor?: string;
    vehicleBrand: string;
    vehicleModel?: string
}

interface VehicleColorInformation {
    hexCode?: string,
    translation?: string
}

const getColor = (colorCode?: string): VehicleColorInformation => {
    switch (colorCode) {
        case "Hvit":
            return {hexCode: "#FFFFFF", translation: Translation.messages().tapnpark.device.colors.white}
        case "Rød":
            return {hexCode: "#CC4141", translation: Translation.messages().tapnpark.device.colors.red}
        case "Blå":
            return {hexCode: "#3A5FD5", translation: Translation.messages().tapnpark.device.colors.blue}
        case "Grønn":
            return {hexCode: "#21B44C", translation: Translation.messages().tapnpark.device.colors.green}
        case "Grå":
            return {hexCode: "#6E6E6E", translation: Translation.messages().tapnpark.device.colors.grey}
        case "Svart":
            return {hexCode: "#000000", translation: Translation.messages().tapnpark.device.colors.black}
        case "Beige":
            return {hexCode: "#EED9C4", translation: Translation.messages().tapnpark.device.colors.beige}
        case "Brun":
            return {hexCode: "#8B4513", translation: Translation.messages().tapnpark.device.colors.brown}
        case "Gul":
            return {hexCode: "#F6CD16", translation: Translation.messages().tapnpark.device.colors.yellow}
        case "Oransje":
            return {hexCode: "#E17400", translation: Translation.messages().tapnpark.device.colors.orange}
        case "Sølv":
            return {hexCode: "#C0C0C0", translation: Translation.messages().tapnpark.device.colors.silver}
        case "Gull":
            return {hexCode: "#D4AF37", translation: Translation.messages().tapnpark.device.colors.gold}
        case "Rosa":
            return {hexCode: "#FF69B4", translation: Translation.messages().tapnpark.device.colors.pink}
        case "Fiolett, lilla":
            return {hexCode: "#713D99", translation: Translation.messages().tapnpark.device.colors.purple}
        case "Turkis":
            return {hexCode: "#75D2CF", translation: Translation.messages().tapnpark.device.colors.turquoise}
        case "Annen":
            return {hexCode: "#3B4365", translation: Translation.messages().tapnpark.device.colors.multicolored}
        default:
            return {hexCode: undefined, translation: undefined}
    }
}

const getCircle = (colorCode?: string) => {
    switch (colorCode) {
        case "Annen":
            return 'vehicle-color split-circle'
        default:
            return 'vehicle-color'
    }
}

const vehicleModel = (brand: string, model?: string) => {
    if (model?.startsWith(brand)) {
        const brandLength = brand.length;
        return model.substring(brandLength);
    } else {
        return model;
    }
}

export const DisplayVehicleCharacteristics = (props: DisplayVehicleColorProps) => {
    if (getColor(props.vehicleColor).hexCode !== undefined && getColor(props.vehicleColor).translation !== undefined) {
        return <div className={'vehicle-characteristics'}>
            <span className={'vehicle-type'}>{props.vehicleBrand} {vehicleModel(props.vehicleBrand, props.vehicleModel)}</span>
            <span className={getCircle(props.vehicleColor)}
                  style={{backgroundColor: getColor(props.vehicleColor).hexCode}}>
        </span>
            <span className={'vehicle-type'}>{getColor(props.vehicleColor).translation}</span>
        </div>;
    } else {
        return <div className={'vehicle-characteristics'}>
            <span className={'vehicle-type'}>{props.vehicleBrand} {vehicleModel(props.vehicleBrand, props.vehicleModel)}</span>
        </div>;
    }
}
