import React from 'react';
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";
import {Translation} from "@autopay.io/translation";
import {constants} from "../../../../../constants";
import {Icon} from "@autopay.io/style";

interface NetworkErrorProps {
    reset: () => void
}

const NetworkError = (props: NetworkErrorProps) => {

    if (localStorage.getItem(constants.DEVICE_ID)) {
        setTimeout(() => {
            props.reset();
        }, constants.TIMEOUT_TIME);
    }

    return (
        <div className={'warning_container'}>
            <Icon icon={'excl_red'} />
            <h1>{Translation.messages().tapnpark.device.error_offline}</h1>
            <Spacer />
            <p>{Translation.messages().tapnpark.device.error_offline_message}</p>
        </div>

    );
}

export default NetworkError;
