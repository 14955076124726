import React from "react";
import {Translation} from '@autopay.io/translation';
import {AioLanguageSwitcher} from '@autopay.io/style';
import WizardNavbar from "@autopay.io/style/lib/components/WizardNavbar/WizardNavbar";

interface HeaderState {
    value: string
}

class AppHeader extends React.Component<{}, HeaderState> {
    render() {
        return(
            <React.Fragment>
                <WizardNavbar translation={Translation} landingUrl={'/'}/>
                <AioLanguageSwitcher
                    supportedLanguages={Translation.supportedLanguages}
                    setSelectedLanguage={Translation.setSelectedLanguage}
                    getSelectedLanguageName={Translation.getSelectedLanguageName}
                />
            </React.Fragment>
        );
    }
}

export default AppHeader;
