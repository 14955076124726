import {Component} from 'react';
import {Translation} from "@autopay.io/translation";
import moment from "moment";
import 'moment/locale/nb';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/pl';
import 'moment/locale/lv';
import 'moment/locale/is';
/* Always have English locale import at the end so it defaults
to English locale if another locale isn't imported  */
import 'moment/locale/en-gb';

class Util extends Component<{}, {}> {

    static secondsToHms = (d: number) => {
        d = Number(d);
        let display = '';

        let h = Math.floor(d / 3600);
        if (h > 0) {
            display += h + " ";
            display += h === 1 ? Translation.messages().tapnpark.device.hour_singular : Translation.messages().tapnpark.device.hour_plural;
            display += " ";
        }

        let m = Math.floor(d % 3600 / 60);
        if (m > 0) {
            display += m + " ";
            display += m === 1 ? Translation.messages().tapnpark.device.minute_singular : Translation.messages().tapnpark.device.minute_plural;
        }

        return display;
    };

    static formatDateTime = (dateTime: string, showDate: boolean) => {
        const momentLocale = moment.parseZone(dateTime).locale(Translation.getSelectedBrowserLocale());
        const time = momentLocale.format("[<span>]LT[</span>]");

        if (showDate) {
            // Format the date and remove the year. It works with the currently supported set of languages.
            const date = momentLocale.format("ll")
                .split(" ")
                .slice(0, -1)
                .join(" ");

            return `${date} ${time}`;
        }

        return `${time}`;
    }
}

export default Util;
