import React from 'react';
import '@autopay.io/style';
import '@autopay.io/style/autopay-io.css';
import './assets/styles/App.scss';
import AppRouter from './router';
import {BrowserRouter, matchPath} from 'react-router-dom';
import AppContainer from './container';
import {Translation} from "@autopay.io/translation"
import Plausible from 'plausible-tracker';
import {createBrowserHistory, Location} from 'history';
import {getAllStringsFromObject} from "@autopay.io/common/lib/getAllStringsFromObject";
import {routes} from "./routes";
import constants from "./constants/config";

const getTnpDeviceDomain = () => {
    if (constants.ENV === 'prod') {
        return `tnp.autopay.io`;
    }
    return `tnp.${constants.ENV}.autopay.io`;
}

// https://plausible.io/docs/integration-guides#npm-package
const plausible = Plausible({domain: `${getTnpDeviceDomain()}`});
export const browserHistory = createBrowserHistory();

class App extends React.Component<{}, {}> {

    public componentDidMount() {
        Translation.addLanguageChangeListener(() => {
            this.forceUpdate();
        });
        this.logPathToPlausible(browserHistory.location);
        browserHistory.listen((location) => this.logPathToPlausible(location));
    }

    private logPathToPlausible = (location: Location) => {
        let path = location.pathname;

        const allPaths = getAllStringsFromObject(routes);
        allPaths.map((route) => {
            if (matchPath(path, {path: route, exact: true}) !== null) {
                path = route;
            }
        });
        plausible.trackPageview({url: path});
    }

    render(): React.ReactNode {
        return (
            <BrowserRouter>
                <AppContainer>
                    <AppRouter/>
                </AppContainer>
            </BrowserRouter>
        );
    }
}

export default App;
