import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Translation} from "@autopay.io/translation";
import Input from "@autopay.io/style/lib/components/Input/Input";
import Button from "@autopay.io/style/lib/components/Button/Button";
import { Redirect } from 'react-router';
import Spacer from "@autopay.io/style/lib/components/Spacer/Spacer";

const InvalidDevice = () => {

    const [registrationHash, setRegistrationHash] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (registrationHash.length > 0) {
            setSubmitted(true);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setRegistrationHash(event.target.value.toUpperCase());

    if (submitted) {
        return  <Redirect  to={"/register/" + registrationHash} />
    } else {
        return (
            <>
                <div className="icon icon_warning_animated" />
                <h1>{Translation.messages().tapnpark.device.error_not_active}</h1>
                <Spacer />
                <h3>{Translation.messages().tapnpark.device.error_not_active_message}</h3>
                <Spacer />
                <form onSubmit={handleSubmit}>
                    <Input type="text" value={registrationHash} className="vehicle-reg-input" onChange={handleChange} />
                    <Button disabled={!registrationHash}>{Translation.messages().common.buttons.ok}</Button>
                </form>
            </>
        );
    }
}

export default InvalidDevice;
